import React from 'react'

import '../style/Slot.css'
import Contact from './Contact'
import FlipCard from './FlipCard'
import Share from './Share'

import { ICON_IMAGES } from '../constants'
import { AUDIO_FILES } from '../constants/audio'

const BASE_PAGE_WIDTH = 600
const BASE_PAGE_HEIGHT = 600

export default class Slot extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            col1: [], // Slot column images
            col2: [],
            col3: [],
            flipTextCount: 0, // To display flip text on slot machine
            isMobileLayout: false,
            isSpinning: false,
            isWinner: false,
            slotCount: 0,
            type: 'main'
        }

        this.col1 = React.createRef()
        this.col2 = React.createRef()
        this.col3 = React.createRef()
        this.columns = [this.col1, this.col2, this.col3]

        this.media = React.createRef() // For scaling slot

        // Timeouts
        this.rowTimeout = null
        this.slotTimeout = null
        this.contactTimeout = null
        this.spinTimeout = null

        // Sounds
        Object.keys(AUDIO_FILES).forEach(key => this[`${key}Sound`] = null)
    }

    addFlipText = () => {
        let flipTextCount = this.state.flipTextCount + 1
        let isWinner = flipTextCount === 4
        this.setState({
            flipTextCount,
            isWinner
        })

        if (flipTextCount === 3) { // Show recycle placard
            this.props.showPlacard()
        }
        
        if (isWinner) {
            this.props.setMobileWinner(true)
        }
    }

    componentDidMount = () => {
        this.setupSlots()
        this.loadSounds()
        this.setPageScale()
        window.addEventListener('resize', this.setPageScale)
    }

    componentDidUpdate = prevProps => {
        if (prevProps.isRecycleScreen !== this.props.isRecycleScreen && this.state.type === 'main') { // Pause slots if recycle screen is maximized
            if (this.props.isRecycleScreen) {
                this.resetTimeouts()
            } else {
                if (this.state.slotCount === 3) {
                    this.setFinalScreen()
                } else {
                    this.spin()
                }
        
            }
        }

        if (this.props.isMuted !== prevProps.isMuted) {
            this.muteAudio()
        }
    }

    checkSize = () => {
        let isMobileLayout = window.innerWidth <= 750
        this.setState({
            isMobileLayout
        })
        this.props.setMobileLayout(isMobileLayout)
        return isMobileLayout
    }

    loadSounds = () => {
        Object.keys(AUDIO_FILES).forEach(file => {
            this[`${file}Sound`] = new Audio(AUDIO_FILES[file])
        })
    }

    muteAudio = () => {
        Object.keys(AUDIO_FILES).forEach(file => {
            this[`${file}Sound`].muted = this.props.isMuted
        })
    }

    setPageScale = () => {
        let isMobileLayout = this.checkSize()
        let scaleX = isMobileLayout ? window.innerWidth * 1.1 / BASE_PAGE_WIDTH : window.innerWidth * .5 / BASE_PAGE_WIDTH
        let scaleY = isMobileLayout ? window.innerHeight * 1.1 / BASE_PAGE_HEIGHT : window.innerHeight * .8 / BASE_PAGE_HEIGHT // web = width = 0.5 height = 0.8 height, width = 0.8 phone = 0.5
        let scale = scaleX > scaleY ? scaleY : scaleX
        if (!this.media.current) return
        this.media.current.style.transform = `scale(${scale})`
        this.media.current.style.webkitTransform = `scale(${scale})`
    }

    setSpinning = isSpinning => {
        this.props.setSpinning(isSpinning)
        this.setState({
            isSpinning
        })
    }

    clearSlot = () => {
        this.setSpinning(false)
        this.resetTimeouts()
        if (this.state.slotCount === 3) {
            this.finalScreenTimeout = setTimeout(this.setFinalScreen, 3000)
        } else {
            this.spinTimeout = setTimeout(this.spin, 100)
        }
    }

    resetTimeouts = () => {
        clearTimeout(this.slotTimeout)
        clearTimeout(this.finalScreenTimeout)
        clearTimeout(this.spinTimeout)
        clearTimeout(this.soundTimeout)
        clearTimeout(this.rowTimeout)
    }

    pauseSound = () => {
        if (this.state.slotCount === 2 || this.state.slotCount === 3) {
            this.winSound.muted = this.props.isMuted
            this.winSound.play().catch(err => {
                console.log('Error playing audio')
            })
        }

        this.addFlipText()

    }

    playTestSounds = () => { // To play audio from beginning to get around iOS restrictions
        Object.keys(AUDIO_FILES).forEach(file => {
            if (file !== 'spin') {
                this[`${file}Sound`].muted = true
                this[`${file}Sound`].play()
            }
        })
    }
    
    pullLever = () => {
        if (this.state.slotCount > 0) return
        this.spin()
        this.playTestSounds()
    }

    renderContact = () => (
        <div>
            <Contact 
                setShare={this.setShare} />
        </div>
    )

    renderCurrentScreen = () => {
        switch (this.state.type) {
            case 'contact':
                return this.renderContact()
            case 'share':
                return this.renderShare()
            default:
                return this.renderMain()
        }
    }

    renderMain = () => (
        <div>
            <div className="loading-screen"></div>
            <div className="logo"></div>
            {this.renderProductImage()}
            {this.state.type === 'product' ? null : this.renderSlotScreen()}
            <FlipCard
                lbs={this.props.lbs}
                flipTextCount={this.state.flipTextCount}
                pullLever={this.pullLever} />
            <div className={`lever ${this.state.slotCount ? 'active' : ''} ${this.state.slotCount === 0 ? 'first' : ''}`}
                onClick={this.pullLever}></div>
        </div>
    )

    renderProductImage = () => (
        <div className={`slots final ${this.state.type === 'product' ? 'visible' : 'hidden'}`}>
            <div className="vid-container">
                <div className="pzaz-product"></div>
            </div>
        </div>
    )

    renderShare = () => (
        <div>
            <Share />
        </div>
    )

    renderSlot = () => (
        <div className="scale-container">
            <div className={`media ${this.state.isMobileLayout ? 'mobile' : ''}`} ref={this.media}>
                <div
                    onClick={this.pullLever} 
                    className={`slot-container slot-${this.state.type} ${this.state.flipTextCount === 4 && this.state.isWinner ? 'winner' : ''}`}>
                    {this.renderCurrentScreen()}
                </div>
            </div>
        </div>
    )

    renderSlotColumns = () => (
        <div className="window">
            <div className="top-fade"></div>
            <div className="bottom-fade"></div>
            {this.columns.map(this.renderSlotColumn)}
      </div>
    )

    renderSlotColumn = (col, idx) => (
        <div className={`outer-col ${idx === 1 ? 'middle-col' : ''}`}>
            <div ref={this[`col${idx + 1}`]} className={`col col${idx + 1}`}>
                {this.state[`col${idx + 1}`].map(this.renderSlotIcon)}
            </div>
        </div>
    )

    renderSlotIcon = (icon, idx) => (
        <img 
            className="icon"
            alt="slot machine emoji" 
            key={`${icon}-${idx}`} 
            src={icon} />
    )

    renderSlotScreen = () => {
        let className = ''
        if (this.state.isSpinning) {
            className = 'spinning'
        } else if (!this.state.slotCount) {
            className = 'wiggle'
        }
        return (
            <div className={`slots ${className}`}>
                {this.renderSlotColumns()}
            </div>
        )
    }

    setContact = () => {
        this.setState({
            type: 'contact',
        })
        this.addFlipText()
    }

    setFinal = () => {
        if (this.state.type !== 'main') return
        for (let idx = 1; idx < 4; idx++) {
            let results = [
                ICON_IMAGES[Math.floor(Math.random() * ICON_IMAGES.length)],
                ICON_IMAGES[Math.floor(Math.random() * ICON_IMAGES.length)],
                ICON_IMAGES[Math.floor(Math.random() * ICON_IMAGES.length)]
            ]
            if (this.state.slotCount === 1) { // Flavors
                if (idx === 1) {
                    results[1] = ICON_IMAGES[8]
                } else if (idx === 2) {
                    results[1] = ICON_IMAGES[3]
                } else {
                    results[1] = ICON_IMAGES[0]
                }
            } else if (this.state.slotCount === 2) { // Recycle
                results[1] = ICON_IMAGES[4]
            } else if (this.state.slotCount === 3) {
                results[1] = ICON_IMAGES[6]
            }
            if (!this[`col${idx}`].current) return
            let icons = this[`col${idx}`].current.querySelectorAll('.icon')
            for (let jidx = 0; jidx < 3; jidx++) {
                icons[jidx].setAttribute('src', results[jidx])
                icons[icons.length - 3 + jidx].setAttribute('src', results[jidx])
            }
        }
        clearTimeout(this.rowTimeout)

    }

    setFinalScreen = () => {
        this.setState({
            type: 'product',
        })
        this.addFlipText()
        this.props.setMobileWinner(false)
        this.contactTimeout = setTimeout(this.setContact, 5000)
    }

    setupSlots = () => {
        let columns = {
            col1: [],
            col2: [],
            col3: []
        }

        for (let idx = 1; idx < 4; idx++) {
            for (let jidx = 0; jidx < 50; jidx++) {
                columns[`col${idx}`].push(ICON_IMAGES[Math.floor(Math.random() * ICON_IMAGES.length)])
            }
        }

        this.setState({
            col1: columns.col1,
            col2: columns.col2,
            col3: columns.col3
        })
    }

    setShare = ev => {
        this.setState({
            type: 'share',
        })
        this.addFlipText()
    }

    spin = () => {
        if (this.props.isRecycleScreen) return
        let flipTextCount = !this.state.slotCount ? 1 : this.state.flipTextCount

        this.setState({
            isSpinning: true,
            slotCount: this.state.slotCount + 1,
            flipTextCount
        })
        this.props.setSpinning(true)
        // this.spinSound.play()
        this.rowTimeout = setTimeout(this.setFinal, 3000)
        this.soundTimeout = setTimeout(this.pauseSound, 4500)
        this.slotTimeout = setTimeout(this.clearSlot, 6000)
    }

    render() {
        return (
            <div className="main-container">
                {this.renderSlot()}
            </div>
        )
    }
}
