const RecycleText = `

<div class="recycle-text">
Pzaz is 200% Plastic Offset: for every capsule sold, two capsules worth of plastic litter are recovered from the environment and properly recycled. 
</div>
<div class="recycle-text recycle1">
This Plastic Negative® pledge is fulfilled in partnership with RePurpose Global, an organization committed to ending plastic waste around the world. 
</div>
<div class="recycle-text">
Together, we’ve already removed and repurposed more than 600 pounds of otherwise ocean-bound litter. Click *here* for more info. And message us at <a href="mailto:sustainability@pzaz.com">sustainability@pzaz.com</a> with any suggestions or questions about our commitment to reducing global plastic waste.
</div>
`

export {RecycleText}