import Spin from '../assets/sounds/spin.mp3'
import Win from '../assets/sounds/win.mp3'
import Star1 from '../assets/sounds/star1.mp3'
import Star2 from '../assets/sounds/star2.mp3'
import Star3 from '../assets/sounds/star3.mp3'
import Star4 from '../assets/sounds/star4.mp3'
import Star5 from '../assets/sounds/star5.mp3'
import Star6 from '../assets/sounds/star6.mp3'
import Star7 from '../assets/sounds/star7.mp3'
import Spray from '../assets/sounds/spray.mp3'


const AUDIO_FILES = {
    spin: Spin,
    win: Win,
    spray: Spray
}

const STAR_AUDIO_FILES = {
    // star1: Star1,
    star2: Star2,
    // star3: Star3,
    // star4: Star4,
    // star5: Star5,
    // star6: Star6,
    // star7: Star7,
}

export {AUDIO_FILES, STAR_AUDIO_FILES}