import React from 'react'

import {FLAVOR_TEXT, FLAVORS1, FLAVORS2} from '../constants/flavor'

import '../style/FlipCard.css'
import '../style/Flavor.css'

export default class Flavor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isDisappearing: false
        }
    }

    renderFlavorIcon = text => (
        <div
            onClick={this.selectFlavor}
            data-flavor={text.split(' ')[0].toLowerCase()} 
            className={`flavor-icon ${text.split(' ')[0].toLowerCase()}`}>
            <div className="flavor-text">
                {text}
            </div>
        </div>
    )

    renderFlipBox = () => (
        <div className="flip-box">
            <div className="flip-box-front">
                <div className="flip-text">
                    <div>{FLAVOR_TEXT}</div>
                </div>
            </div>
        </div>
    )

    renderMuteButton = () => (
        <div 
          onClick={this.props.mute}
          className={`mute-icon ${this.props.isMuted ? 'unmute' : 'mute'}`}></div>
    )

    selectFlavor = ev => {
        this.setState({
            isDisappearing: true
        })
        setTimeout(ev => {
            this.props.selectFlavor(ev)
        }, 1500)
    }

    render() {
        return (
            <div className={`flavor-container ${this.state.isDisappearing ? 'disappear' : ''}`}>
                <div className="flavor-rescale">
                    <div className="logo"></div>
                    {this.renderFlipBox()}
                    <div className="flavoricons">
                        <div>
                            {FLAVORS1.map(this.renderFlavorIcon)}
                        </div>
                        <div>
                            {FLAVORS2.map(this.renderFlavorIcon)}
                        </div>
                    </div>
                    {this.renderMuteButton()}
                </div>

            </div>
        )
    }
}
