import React from 'react'

import '../style/FlipCard.css'
import {FLIPCARD_TEXT} from '../constants/flipcard'

export default class FlipCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            frontText: FLIPCARD_TEXT[0],
            backText: FLIPCARD_TEXT[1],
            hasChanged: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.flipTextCount !== this.props.flipTextCount) {
            if (this.props.flipTextCount >= FLIPCARD_TEXT.length) return
            if (this.props.flipTextCount === 3) {
                // let greenTextEl = document.getElementById('green-text')
                // greenTextEl.innerHTML = `
                //     <div class="green">${this.props.lbs}</div>
                // `
                // setTimeout(() => {
                //     let greenTextEl = document.getElementById('green-text')
                //     greenTextEl.innerHTML = `
                //         <div class="new-green">${this.props.lbs + 1}</div>
                //     `
                // }, 1000) 
            }
            let frontText, backText
            if (this.props.flipTextCount % 2 === 1) {
                this.setState({
                    hasChanged: 'back',
                })
                frontText = this.props.flipTextCount === FLIPCARD_TEXT.length - 1 ? '' : FLIPCARD_TEXT[this.props.flipTextCount + 1]
            } else {
                this.setState({
                    hasChanged: 'front'
                })
                backText = this.props.flipTextCount === FLIPCARD_TEXT.length - 1 ? '' : FLIPCARD_TEXT[this.props.flipTextCount + 1]
            }
            setTimeout(() => {
                this.setState({
                    frontText: frontText ? frontText : this.state.frontText,
                    backText: backText ? backText : this.state.backText
                })
            }, 2000)
        }
    }

    reset = () => {
        this.setState({
            hasChanged: false
        })
    }

    render() {
        let {frontText, backText, hasChanged} = this.state
        return (
            <div className="flip-box">
                <div className={`flip-box-inner ${hasChanged ? hasChanged : ''}`}>
                    <div 
                        className={`flip-box-front ${frontText.className}`}>
                        <div className="flip-text">
                            <div dangerouslySetInnerHTML={{__html: frontText.text}} />
                        </div>
                    </div>
                    <div className={`flip-box-back ${backText.className}`}>
                        <div className="flip-text">
                            <div className="back-text" dangerouslySetInnerHTML={{__html: backText.text}} />
                        </div>
                    </div>
                </div>
            </div> 
        )
    }
}