import React from 'react'

import '../style/Share.css'

import { SHARE_TEXT } from '../constants/share'
import { DEVICES, getMobileOperatingSystem, isMobile } from '../utils/device'

const MESSAGE = 'Check out PZAZ!!!!'

export default class Share extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            appearing: '',
            isMobile: isMobile(),
            href: `sms:?&body=${MESSAGE}`
        }
    }

    componentDidMount() {
        if (this.state.isMobile) {
            this.checkOperatingSystem()
        } else {
            this.setState({
                href: `mailto:?subject=Pzaz&body=${MESSAGE}`
            })
        }

        setTimeout(() => {
            this.setState({
                appearing: 'scale'
            })
        }, 300)

    }

    checkOperatingSystem = () => {
        let device = getMobileOperatingSystem()
        let href = ''
        switch (device) {
            case DEVICES.ANDROID:
                href = `sms:?body=${MESSAGE}`
                break
            case DEVICES.WINDOWS:
                href = `sms://?body=${MESSAGE}`
                break
            case DEVICES.IPHONE:
                href = `sms:?&body=${MESSAGE}`
                break
            default:
                href = `sms:?&body=${MESSAGE}`
                break
        }
        this.setState({
            href
        })
    }

    render() {
        return (
            <div className={`share-window ${this.state.appearing}`}>
                <div className="logo"></div>
                <div className="window share">
                    <div className="share-text">
                        <div className="upper">
                            {SHARE_TEXT.UPPER[0]}
                        </div>
                        <div className="lower">
                            {SHARE_TEXT.UPPER[1]}
                        </div>
                    </div>
                    <a href={this.state.href} className="share-text button">
                        <div className="button-text">{SHARE_TEXT.LINK}</div>
                    </a>
                    <div className="share-text lower">{SHARE_TEXT.LOWER}</div>
                </div>
            </div>

        )
    }
}
