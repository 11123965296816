const FLAVOR_TEXT = 'TO ENTER, CONFIRM YOUR FAVORITE FLAVOR'

const FLAVORS1 = [
    'CHERRY PIE',
    'SOUR APPLE',
    'RIPE PEACH',
    'ICY MINT'
]

const FLAVORS2 = [
    'PINEAPPLE COCONUT',
    'PASSION GUAVA',
    'MANGO',
    'WATER MELON'
]

export {FLAVOR_TEXT, FLAVORS1, FLAVORS2}