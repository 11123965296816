import Leaf from '../assets/sloticons/leaf.svg'
import Lightning from '../assets/sloticons/lightning.svg'
import NoMouth from '../assets/sloticons/nomouth.svg'
import Orange from '../assets/sloticons/orange.svg'
import Recycle from '../assets/sloticons/recycle.svg'
import Sleepy from '../assets/sloticons/sleepy.svg'
import Stars from '../assets/sloticons/stars.svg'
import TwoLeaf from '../assets/sloticons/twoleaf.svg'
import Watermelon from '../assets/sloticons/watermelon.svg'

const ICONS = [
    "stars",
    "recycle",
    "leaf",
    "nomouth",
    "lightning",
    "twoleaf",
    "sleepy",
    "orange",
    "watermelon"
]

const ICON_IMAGES = [
    Leaf,
    Lightning,
    NoMouth,
    Orange,
    Recycle,
    Sleepy,
    Stars,
    TwoLeaf,
    Watermelon
]

export {ICONS, ICON_IMAGES}
