import React from 'react'

import Flavor from './Flavor'
import Placard from './Placard.js'
import Slot from './Slot'
import MarqueeCanvas from './MarqueeCanvas'
import '../style/Recycle.css'

import { RecycleText } from '../constants/recycle'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isFlavorScreen: true,
      isMobileWinner: false,
      isMobileLayout: false,
      isMuted: true,
      isRecycleScreen: false,
      isRepeatUser: false,
      isSpinning: false,
      lbs: 0,
      recycle: '',
      showPlacard: false
    }
  }

  addLbs = () => {
    fetch('/api/recycle', {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lbs: this.state.lbs + 1
      })
    })
  }

  checkCookies = () => {
    if (!document.cookie.split('; ').find(row => row.startsWith('pzaz'))) {
      document.cookie = "pzaz=true; expires=Fri, 31 Dec 9999 23:59:59 GMT"
    } else {
      this.setState({
        isRepeatUser: true
      })
    }
  }

  componentDidMount() {
    this.getLbs()
    this.checkCookies()
  }

  getLbs = () => {
    fetch('/api/recycle', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache'
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        lbs: data.lbs
      })
      this.addLbs() // TO DO: Move to contact
    })
    .catch(err => {
      console.error(err)
    })
  }

  mute = () => {
    this.setState({
      isMuted: !this.state.isMuted
    })
  }

  renderFlavorScreen = () => (
    <Flavor
      isMuted={this.state.isMuted}
      mute={this.mute}
      selectFlavor={this.selectFlavor} />
  )

  renderMuteButton = () => (
    <div 
      onClick={this.mute}
      className={`mute-icon ${this.state.isMuted ? 'unmute' : 'mute'}`}></div>
  )

  renderPlacard = () => (
    <Placard 
      showPlacard={this.state.showPlacard}
      isRecycle={this.state.isRecycleScreen} 
      lbs={this.state.lbs}
      setRecycle={this.setRecycle} />
  )

  renderRecycleScreen = () => (
    <div>
      <div className="close" onClick={this.setRecycle}></div>
      <div className={`recycle-container ${this.state.recycle}`}>
        <div className="recycle-subcontainer" 
          dangerouslySetInnerHTML={{__html: RecycleText}} />
      </div>
    </div>
  )

  selectFlavor = ev => {
    this.setState({
      isFlavorScreen: false
    })
  }

  setMobileLayout = isMobileLayout => {
    this.setState({
      isMobileLayout
    })
  }

  setMobileWinner = isWinner => {
    this.setState({
      isMobileWinner: isWinner
    })
  }

  setRecycle = () => {
    this.setState({
        isRecycleScreen: !this.state.isRecycleScreen
    })
    setTimeout(() => {
      this.setState({
        recycle: !this.state.isRecycleScreen ? '' : 'visible'
      })
    }, 50)
}

  setSpinning = isSpinning => {
    this.setState({
      isSpinning
    })
  }

  showPlacard = () => {
    this.setState({
      showPlacard: true
    })
  }

  render() {
    return (
      <div className="App">
        <div className={`app-container ${this.state.isMobileWinner && this.state.isMobileLayout ? 'winner' : ''}`}>
          <Slot 
            setSpinning={this.setSpinning}
            lbs={this.state.lbs}
            showPlacard={this.showPlacard}
            setEmailStar={this.setEmailStar}
            isRecycleScreen={this.state.isRecycleScreen}
            addLbs={this.addLbs}
            isMuted={this.state.isMuted}
            setMobileLayout={this.setMobileLayout}
            setMobileWinner={this.setMobileWinner}
            isRepeatUser={this.state.isRepeatUser} />
          {this.renderPlacard()}
        </div>
        {this.state.isRecycleScreen ? this.renderRecycleScreen() : null}
        {this.state.isFlavorScreen ? this.renderFlavorScreen() : null}
        <MarqueeCanvas 
          isMobileLayout={this.state.isMobileLayout}
          isFast={this.state.isSpinning} />
        {this.renderMuteButton()}
      </div>
    )
  }
}

export default App
