import React from 'react'

import '../style/Placard.css'
import {PLACARD_TEXT} from '../constants/placard'

export default class PlasticPlacard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            className: '' ,
            hasChanged: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                className: 'show'
            })
        }, 100)
    }

    renderText = () => {
        if (!this.state.hasChanged && this.props.showPlacard) {
            setTimeout(() => {
                this.setState({
                    hasChanged: true
                })
                console.log('dfdg')
                let greenTextEl = document.getElementById('green')
                greenTextEl.innerHTML = `
                    <div class="new-green">${this.props.lbs + 1}</div>
                `
            }, 1000)
        }
        return (
            <div>
                <div className="green">
                    <div id="green">{this.props.lbs}</div>
                    LB LESS LITTER</div>
            </div>
        )
    }
    
    render() {
        return (
            <div 
                onClick={this.props.setRecycle}
                className={`placard-container ${this.props.showPlacard ? 'show' : 'hide'} ${this.props.isRecycle ? 'recycle' : ''}`}>
                <div className="placard">
                    <div className="placard-text">
                        {this.renderText()}
                    </div>
                    <div 
                        className={`placard-info ${this.props.isRecycle ? 'recycle' : ''}`}>
                    </div>
                </div>
            </div>
        )
    }
}
