import React from 'react'

import {validateEmail} from '../utils/validate'

import '../style/Contact.css'

export default class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            appearing: '',
            phone: '',
            isError: false
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                appearing: 'scale'
            }) 
        }, 200)
    }

    onInput = ev => {
        this.setState({
            [ev.target.dataset.type]: ev.target.value
        })
    }

    onSubmit = () => {
        this.saveContact()
        this.props.setShare()
    }

    saveContact = () => {
        fetch('/api/user', {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone: this.state.phone
            })
        })
        .then(() => {
            this.props.setShare()
        })
        .catch(err => {
            console.error(err)
        })
    }

    render() {
        return (
            <div className={`contact-container ${this.state.appearing}`}>
                <div className="logo"></div>
                <input 
                    className={`contact-input phone`} 
                    data-type="phone"
                    onInput={this.onInput}
                    placeholder="PHONE #" />
                <button 
                    className={`contact-button`}
                    onClick={this.onSubmit}>
                        TEXT ME MY FREE PZAZ
                </button>
            </div>
        )
    }
}