const FLIPCARD_TEXT = [
    {
        className: 'first',
        text: `
            <div class="main-flipcard first">FEEL LIKE A WINNER?</div>
        `
    },
    {
        className: '',
        text: `
            WE ARE SPREADING POSITIVE ENERGY
        `
    },
    {
        className: '',
        text: `
            CAFFEINE + VITAMIN C + ZINC + ELECTROLYTES
        `
    },
    {
        className: '',
        text: `
            IN A SUSTAINABLE, POCKETABLE SPRAY
        `
    },
    {
        className: '',
        text: `
            WINNER, WINNER!!
        `
    },
    {
        className: '',
        text: `
            WHEN WE LAUNCH, WE'LL SEND YOU A FREE CAPSULE.
        `
    },
    {
        className: 'flip-box-winner',
        text: `
            REDEEM MY FREE PZAZ
        `
    },
]

export {FLIPCARD_TEXT}
