import React from 'react'
import '../style/MarqueeCanvas.css'

import {MARQUEE_TEXT} from '../constants/marquee'

var TextPositionsHorizontal = {
    TextPositionsTop: [],
    TextPositionsBottom: []    
}

var TextPositionsVertical = {
    TextPositionsLeft: [],
    TextPositionsRight: []
}

class MarqueeCanvas extends React.Component {
    constructor(props) {
        super(props)
        this.canvas = React.createRef()
        this.text = React.createRef()
        this.ctx = null
        this.PERCENT_HEIGHT = 0.05
        this.FONT_PERCENT = 0.03

        this.state = {
            changeTime: false
        }
    }

    calculateText = () => {
        TextPositionsHorizontal = {
            TextPositionsTop: [],
            TextPositionsBottom: []    
        }
        
        TextPositionsVertical = {
            TextPositionsLeft: [],
            TextPositionsRight: []
        }
        let ttext = MARQUEE_TEXT.Top.Text
        this.ctx.font = `${window.innerHeight * this.FONT_PERCENT}px Druk`
        let twidth = Math.ceil(this.ctx.measureText(ttext).width)
        let stext = MARQUEE_TEXT.Left.Text
        let swidth = Math.ceil(this.ctx.measureText(stext).width)

        for (let position in MARQUEE_TEXT) {
            let length = 6


            for (let idx = 0; idx < length; idx++) {
                if (position === 'Top') {
                    TextPositionsHorizontal[`TextPositions${position}`].push({
                        x: idx * twidth,
                        text: MARQUEE_TEXT[position].Text.toUpperCase(),
                        width: twidth
                    })
                } else if (position === 'Bottom') {
                    TextPositionsHorizontal[`TextPositions${position}`].push({
                        x: idx * twidth,
                        text: MARQUEE_TEXT[position].Text.toUpperCase(),
                        width: twidth
                    })
                }
                else {
                    if (!this.props.isMobileLayout) {
                        TextPositionsVertical[`TextPositions${position}`].push({
                            x: idx * swidth,
                            text: MARQUEE_TEXT[position].Text.toUpperCase(),
                            width: swidth
                        })
                    }
                }
            }
        }
    }

    componentDidMount() {
        this.canvas.current.width = window.innerWidth
        this.canvas.current.height = window.innerHeight
        this.ctx = this.canvas.current.getContext('2d')
        this.setupDeviceRatio()

        this.calculateText()
        setTimeout(this.calculateText, 500)
        window.addEventListener('resize', this.resizeCanvas)
        window.addEventListener('orientationchange', this.resizeCanvas)

        this.draw()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.isFast !== this.props.isFast) {
            this.setState({
                changeTime: true
            })
        }
    }

    draw = time => {
        if (this.state.changeTime) {
            this.time = time
            this.setState({
                changeTime: false
            })
        }

        this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)
        this.ctx.font = `${window.innerHeight * this.FONT_PERCENT}px Druk`
        var TextPositionsHorizontalCopy = {}
        var TextPositionsVerticalCopy = {}
        let height = window.innerHeight * this.PERCENT_HEIGHT


        if (!this.props.isMobileLayout) {
            this.ctx.fillStyle = '#000000'
            this.ctx.fillRect(0, 0, height, window.innerHeight)
            this.ctx.fillRect(window.innerWidth - height, 0, height, window.innerHeight)
            this.ctx.fillStyle = '#ffffff'
    
            this.ctx.rotate(Math.PI / 2)
            for (let position in TextPositionsVertical) {
            
                TextPositionsVerticalCopy[position] = TextPositionsVertical[position].slice()
                TextPositionsVertical[position].forEach((text, jidx) => {
                    if (time - this.time < 1000) {
                        let t = (time - this.time) / 1000
                        t = t < 0.5 ? 0.5 : t
                        text.x -= this.props.isFast ? (t)* 5 : 5 - (t)* 4.5
                    } else {
                        text.x -= this.props.isFast ? 5 : 0.5
                    }                
                    if (text.x < -TextPositionsVertical[position][jidx].width) {
                        TextPositionsVerticalCopy[position].splice(jidx, 1)
                        TextPositionsVerticalCopy[position].splice(0, 0, {
                            x: TextPositionsVertical[position][jidx].width * 5,
                            text: TextPositionsVertical[position][jidx].text,
                            width: TextPositionsVertical[position][jidx].width
                        })
                    }
    
                    let y
                    switch (position) {
                        case 'TextPositionsLeft':
                            y = -(height - this.FONT_PERCENT * window.innerHeight ) / 2
                            break
                        case 'TextPositionsRight':
                            y = - window.innerWidth + (height - (this.FONT_PERCENT * window.innerHeight ) / 2)
                            break
                        default:
                            break
                    }
                    this.ctx.fillText(text.text, text.x, y)
                })
            }
        }

        this.ctx.setTransform(1, 0, 0, 1, 0, 0)

        this.ctx.fillStyle = '#000000'
        this.ctx.fillRect(0, 0, window.innerWidth, height)
        this.ctx.fillRect(0, window.innerHeight - height, window.innerWidth, height)

        this.ctx.fillStyle = '#FFFFFF'


        for (let position in TextPositionsHorizontal) {
            TextPositionsHorizontalCopy[position] = TextPositionsHorizontal[position].slice()
            TextPositionsHorizontal[position].forEach((text, jidx) => {
                if (time - this.time < 1000) {
                    let t = (time - this.time) / 1000
                    t = t < 0.5 ? 0.5 : t
                    text.x -= this.props.isFast ? (t)* 5 : 5 - (t)* 4.5
                } else {
                    text.x -= this.props.isFast ? 5 : 0.5
                }
                if (text.x < -TextPositionsHorizontal[position][jidx].width) {
                    TextPositionsHorizontalCopy[position].splice(jidx, 1)
                    TextPositionsHorizontalCopy[position].splice(0, 0, {
                        x: TextPositionsHorizontal[position][jidx].width * 5,
                        text: TextPositionsHorizontal[position][jidx].text,
                        width: TextPositionsHorizontal[position][jidx].width
                    })                    
                }

                let y
                switch (position) {
                    case 'TextPositionsTop':
                        y = height - (height - this.FONT_PERCENT * window.innerHeight ) / 2
                        break
                    case 'TextPositionsBottom':
                        y = window.innerHeight - ((height - this.FONT_PERCENT * window.innerHeight ) / 2)
                        break
                    default:
                        break
                }
                this.ctx.fillText(text.text, text.x, y)
            })
        }
        TextPositionsVertical = TextPositionsVerticalCopy
        TextPositionsHorizontal = TextPositionsHorizontalCopy
        requestAnimationFrame(this.draw)
    }

    resizeCanvas = () => {
        this.canvas.current.width = window.innerWidth
        this.canvas.current.height = window.innerHeight
        this.calculateText()
    }

    setupDeviceRatio = () => {
        // var dpr = window.devicePixelRatio || 1
        // Get the size of the canvas in CSS pixels.
        // var rect = this.canvas.current.getBoundingClientRect()
        // Give the canvas pixel dimensions of their CSS
        // size * the device pixel ratio.
        // this.canvas.current.width = window.innerWidth * dpr
        // this.canvas.current.height = window.innerHeight * dpr
        // Scale all drawing operations by the dpr, so you
        // don't have to worry about the difference.
        this.ctx.scale(window.innerWidth, window.innerHeight)
    }

    render() {
        return (
            <div>
                <canvas 
                    id="marquee-canvas"
                    ref={this.canvas} />
                {/* <div ref={this.text} className="marquee-text">{TEST_TEXT}</div> */}
            </div>
        )
    }
}


export default MarqueeCanvas